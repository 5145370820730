import { Col, Row, Carousel, Button } from "antd";
import React from "react";
import {
  UserOutlined,
  ArrowUpOutlined,
  AimOutlined,
  MonitorOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  DeploymentUnitOutlined
} from "@ant-design/icons";
import Side1 from "../images/side1.svg";
import Side2 from "../images/side2.svg";
import Side3 from "../images/side3.svg";
import Side4 from "../images/side4.svg";
import User1 from "../images/user1.jpg";
import User2 from "../images/user2.jpg";
import User3 from "../images/user3.jpg";
import Footer from "../FooterSection/index";
import Nav from "../Nav";
import GoToTop from "../External/GoToTop";
import { Link } from "react-router-dom";
import Laptop from "../images/tab.jpg";
const HomeContent = (props) => {
  return (
    <div>
      <div className="homepage1">
        <div className="homePage">
          <div style={{ backgroundColor: "transparent" }}>
            <Nav />
          </div>
          <Row>
            <Col xs={24} sm={12}>
              <div className="title">
                <h1>Boost your performance with Artificial Intelligence</h1>
              </div>
              <div className="content">
                <p>
                Understanding your customer deeply with AI - Smartest and fastest way to get employee insights at a click away
                </p>
                <Link to="/product">
                  <a href="#">
                    <button className="getADemo">Get a demo</button>
                  </a>
                </Link>
              </div>
              <div className="optional">
                <img src={Laptop} className="laptop" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="statistics">
          <div className="numbers">
            <div className="title">
              <h1>Driven By Performance</h1>
            </div>
            <div className="content">
              <Row>
                <Col xs={24} sm={8}>
                  <div className="icon">
                  </div>
                  <div className="point">
                    <p><span className = "spanCont">8X</span><br/> Faster than similiar platform</p>
                  </div>
                </Col>
                <Col xs={24} sm={8}>
                  <div className="icon">
                  </div>
                  <div className="point">
                    <p><span className = "spanCont">5X</span><br/> Increase in conversion and retention rate</p>
                  </div>
                </Col>
                <Col xs={24} sm={8}>
                  <div className="icon">
                  </div>
                  <div className="point">
                    <p><span className = "spanCont">75%</span><br/> Drive Operational Efficiency</p>
                  </div>
                </Col>
              </Row>
            </div>
        </div>
      </div>

      <div className="features">
       <div className="container-1140">
          <div className="leftText">
            <Row>
              <Col xs={24} sm={12}>
                <div className="feature">
                  <div className="title">
                    <h1>Capture and analyze every action</h1>
                  </div>
                  <div className="content">
                    <p>
                      SicherAI captures every single interaction, analyzes
                      customer interaction, behavioural changes, emotion
                      identification whether being voice, text or video and
                      provides a simple, scalable solution to deliver a bottom
                      line impact.
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12}>
                <div className="LeftImg">
                  <img src={Side1} className="sideImg" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="features" style={{ background: "#DCD9D4" }}>
        <div className="container-1140">
          <div className="RightText">
            <Row className="parent">
              <Col xs={24} sm={12}>
                <div className="RightImg">
                  <img src={Side2} className="sideImg" />
                </div>
              </Col>
              <Col xs={24} sm={12}>
                <div className="feature">
                  <div className="title">
                    <h1>Understand what drives performance</h1>
                  </div>
                  <div className="content">
                    <p>
                      Quickly identify what top performers do differently so you
                      can replicate winning behaviors across the entire team.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="features">
        <div className="container-1140">
          <div className="leftText">
            <Row>
              <Col xs={24} sm={12}>
                <div className="feature">
                  <div className="title">
                    <h1>Monitor Agents performance and help them boost</h1>
                  </div>
                  <div className="content">
                    <p>
                      Show agents how to improve by leaving personalized coaching
                      tips right within the transcript. Boost transparency and
                      morale by using data to help the agents who need it the most
                      quickly.
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12}>
                <div className="LeftImg">
                  <img src={Side3} className="sideImg" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="features" style={{ background: "#DCD9D4" }}>
        <div className="container-1140">
          <div className="RightText">
            <Row className="parent">
              <Col xs={24} sm={12}>
                <div className="RightImg">
                  <img src={Side4} className="sideImg" />
                </div>
              </Col>
              <Col xs={24} sm={12}>
                <div className="feature">
                  <div className="title">
                    <h1>Easy integration and Custom Dashboard</h1>
                  </div>
                  <div className="content">
                    <p>
                      Review, score and analyze agent performance with an
                      integrated AI driven customised dashboard.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="whyUs1">
        <div className="container-1140">
          <div className="reasons">
            <div className="title">
              <h1>Why SicherAI?</h1>
            </div>
            <div className="content ">
              <div class="main-container">
                <div class="cards">
                  <Row>
                    <Col xs = {24} sm = {12}>
                    <div class="card2 card-1">
                    <div class="card__icon">
                    <img src="https://img.icons8.com/ios/64/000000/chromatography.png" style = {{filter:'invert(100%)'}}/>
                    </div>
                    <p class="card__exit">
                      <i class="fas fa-times"></i>
                    </p>
                    <h2 class="card__title" style = {{fontWeight:"bolder"}}>Real time interaction monitoring</h2>
                    <p className="theory">
                      Access Deep conversation understanding and rich enterprise
                      integrations
                    </p>
                  </div>
                  
                    </Col>
                    <Col xs = {24} sm = {12}>
                    <div class="card2 card-2">
                    <div class="card__icon">
                      <img src = "https://img.icons8.com/ios/64/000000/dashboard.png" style = {{filter:"invert(100%)"}}/>
                    </div>
                    <p class="card__exit">
                      <i class="fas fa-times"></i>
                    </p>
                    <h2 class="card__title" style = {{fontWeight:"bolder"}}>
                      Customizable Dashboard and Scorecard
                    </h2>
                    <p className="theory">
                      Review, score and analyze agent performance with  AI driven QA flow
                    </p>
                  </div>
                  
                    </Col>
                  </Row>
                  <Row>
                    <Col xs = {24} sm = {12}>
                    <div class="card2 card-3">
                    <div class="card__icon">
                    <img src="https://img.icons8.com/ios/50/000000/data-protection.png" style ={{filter:'invert(100%)'}}/>
                    </div>
                    <p class="card__exit">
                      <i class="fas fa-times"></i>
                    </p>
                    <h2 class="card__title" style = {{fontWeight:"bolder"}}>Data Privacy and <br/>safety</h2>
                    <p className="theory">
                      Get Assured of your data privacy issue and ensure your safety without any risks
                    </p>
                  </div>
                  
                    </Col>
                    <Col xs = {24} sm = {12}>
                    <div class="card2 card-4">
                    <div class="card__icon">
                    <img src="https://img.icons8.com/ios-filled/64/000000/launched-rocket.png" style = {{filter:"invert(100%)"}}/>
                    </div>
                    <p class="card__exit">
                      <i class="fas fa-times"></i>
                    </p>
                    <h2 class="card__title" style = {{fontWeight:"bolder"}}>
                      Deployed to your existing platform
                    </h2>
                    <p className="theory">
                      Get your data insights wherever you work at any time easily with the help of SicherAI
                    </p>
                  </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="slideShow">
        <div className="articles">
          <div></div>
          <div className="title">
            <h1>Testimonials</h1>
          </div>
          <div></div>
          <Carousel autoplay>
            <div className="slide">
              <h1>Perks of knowing ML!!</h1>
            </div>
            <div className="slide">
              <h1>Learn ML today!!</h1>
            </div>
            <div className="slide">
              <h1>Fields in ML</h1>
            </div>
            <div className="slide">
              <h1>Natural language processing</h1>
            </div>
          </Carousel>
          <div style={{ paddingBottom: "5%" }}></div>
        </div>
  </div>*/}
      <div className="tryOut" style = {{background:'#EBEBEB'}}>
        <div className="container-1140">
          <div className="trySection">
            <h1>Help your customer fall in love again!</h1>
            <h2>Ready to experience SicherAI </h2>
            <Link to="/product">
              <button className="demo1">Try our demo now!</button>
            </Link>
          </div>
        </div>
      </div>
      <div>
        <div class=" ocean">
          <div class="wave"></div>
          <div class="wave"></div>
          <div class="wave"></div>
        </div>
      </div>
      <Footer />
      <GoToTop />
    </div>
  );
};

export default HomeContent;
